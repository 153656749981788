import { applyMiddleware, createStore, compose } from "redux";
import createSagaMiddleware from 'redux-saga';


import rootReducer from "./Reducers";
import rootSage from "./Sagas/sagas";

const sagaMiddleware = createSagaMiddleware();

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancer(applyMiddleware(sagaMiddleware)))

sagaMiddleware.run(rootSage);

export default store;